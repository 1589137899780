<template>
  <div>
    <component
        v-if="notEmpty(testJSON)"
        :is="testJSON.type"
        :key="testJSON.key"
        :obj="testJSON"
    ></component>
  </div>
</template>

<script>

export default {
  data () {
    return {
      testJSON: {
        'key': 'Root',
        'type': 'LhRoot',
        'actions': [],
        'attributes':
            {
              'version':
                  '0.0.1',
              'pageTitle': 'About dev',
              'backgroundUrl': 'https://api.dev.automationtool.technieken.be/assets/img/lighthouse.svg'
            }
        ,
        'styles':
            [], 'children':
            [{
              'key': 'Root_Sidebar',
              'type': 'LhSidebar',
              'actions': [],
              'attributes': [],
              'styles': [],
              'children': [{
                'key': 'root-menu-logo-client',
                'type': 'LhImage',
                'actions': [{ 'url': '/', 'type': 'redirect', 'event': 'click' }],
                'attributes': {
                  'src': 'https://api.dev.automationtool.technieken.be/assets/img/logo.svg',
                  'collapse-src': 'https://api.dev.automationtool.technieken.be/assets/img/logo_small.svg',
                  'class': 'client-logo'
                },
                'styles': { 'cursor': 'pointer' },
                'children': []
              },
                {
                'key': 'root-menu-scroll-content',
                'type': 'LhSidebarScrollContent',
                'actions': [],
                'attributes': [],
                'styles': [],
                'children': [{
                  'key': 'root-menu-nav-list',
                  'type': 'LhNavList',
                  'actions': [],
                  'attributes': [],
                  'styles': [],
                  'children': [{
                    'key': 'root-menu-nav-list-item-1',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_INDEX', 'action': '/', 'icon': 'home' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-2',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_CLIENTS', 'action': '/clients', 'icon': 'file-alt' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-3',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_PROJECTS', 'action': '/projects', 'icon': 'chart-pie' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-4',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_PAGES', 'action': '/pages', 'icon': 'users' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-5',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_CONFIG', 'action': '/users', 'icon': 'cog' },
                    'styles': [],
                    'children': [{
                      'key': 'root-menu-nav-sub-list-5',
                      'type': 'LhNavList',
                      'actions': [],
                      'attributes': [],
                      'styles': [],
                      'children': [{
                        'key': 'root-menu-nav-sub-list-5-item-1',
                        'type': 'LhNavListItem',
                        'actions': [],
                        'attributes': { 'content': 'SIDEBAR_USERS', 'action': '/users' },
                        'styles': [],
                        'children': []
                      }, {
                        'key': 'root-menu-nav-sub-list-5-item-2',
                        'type': 'LhNavListItem',
                        'actions': [],
                        'attributes': { 'content': 'SIDEBAR_BLOCKS', 'action': '/pageblocks' },
                        'styles': [],
                        'children': []
                      }]
                    }]
                  }]
                }]
              },
                {
                'key': 'root-menu-logo-group',
                'type': 'LhDiv',
                'actions': [],
                'attributes': [],
                'styles': [],
                'children': [{
                  'key': 'root-menu-logo-lighthouse',
                  'type': 'LhImage',
                  'actions': [{ 'url': '/', 'type': 'redirect', 'event': 'click' }],
                  'attributes': {
                    'src': 'https://api.dev.automationtool.technieken.be/assets/img/lighthouse_logo_grey.svg',
                    'class': 'lighthouse-logo'
                  },
                  'styles': { 'cursor': 'pointer' },
                  'children': []
                }]
              }, {
                'key': 'root-menu-button-group',
                'type': 'LhButtonGroup',
                'actions': [],
                'attributes': [],
                'styles': [],
                'children': [{
                  'key': 'root-menu-col-1',
                  'type': 'LhCol',
                  'actions': [],
                  'attributes': [],
                  'styles': [],
                  'children': [{
                    'key': 'root-menu-user-button',
                    'type': 'LhIconButton',
                    'actions': [{ 'url': '/settings', 'type': 'update', 'method': 'GET', 'event': 'click' }],
                    'attributes': { 'icon': 'user-cog', 'class': 'sidebar-user-settings-button' },
                    'styles': [],
                    'children': []
                  }]
                }, {
                  'key': 'root-menu-col-2',
                  'type': 'LhCol',
                  'actions': [],
                  'attributes': [],
                  'styles': [],
                  'children': [
                      {
                    'key': 'root-menu-logout-button',
                    'type': 'LhIconButton',
                    'actions': [{ 'url': '/logout', 'type': 'redirect', 'method': 'POST', 'event': 'click' }],
                    'attributes': { 'icon': 'sign-out-alt', 'class': 'sidebar-user-logout-button' },
                    'styles': [],
                    'children': []
                  }
                  ]
                },
                  {
                    'key': 'root-menu-col-3',
                    'type': 'LhCol',
                    'actions': [],
                    'attributes': [],
                    'styles': [],
                    'children': [
                      {
                        'key': 'toggle-translations-button',
                        'type': 'LhIconButton',
                        'actions': [
                          {
                            event: 'click',
                            method: 'translations-toggle-view',
                            type: 'emit',
                            url: ''
                          }
                        ],
                        'attributes': {
                          'name': 'toggle-translations-button',
                          icon: 'globe',
                          transparent: true,
                          disabled: this.loadingJSON
                        },
                        'styles': [],
                        'children': []
                      }
                    ]
                  },
                  {
                    'key': 'root-menu-col-4',
                    'type': 'LhCol',
                    'actions': [],
                    'attributes': [],
                    'styles': [],
                    'children': [
                      {
                        'key': 'translations-popup-button',
                        'type': 'LhIconButton',
                        'actions': [
                          {
                            event: 'click',
                            method: '',
                            type: '',
                            url: ''
                          }
                        ],
                        'attributes': {
                          'name': 'translations-popup-button',
                          icon: 'file-alt',
                          transparent: true,
                          disabled: this.loadingJSON
                        },
                        'styles': [],
                        'children': []
                      }
                    ]
                  }
                  ]
              }]
            },
              {
                "key":"main-admin-dashboard",
                "type":"LhMainPage",
                "actions":[

                ],
                "attributes":[

                ],
                "styles":[

                ],
                "children":[
                  {
                    "key": "pharmacy-edit-col",
                    "type": "LhCol",
                    "actions": [],
                    "attributes": {
                      "cols": 12
                    },
                    "styles": [],
                    "children": [
                      {
                        "key": "pharmacy-config-tab-title",
                        "type": "LhH3",
                        "actions": [],
                        "attributes": {
                          "content": "PHARMACY_CONFIG_INFO"
                        },
                        "styles": [],
                        "children": []
                      },
                      {
                        "key": "pharmacy-edit-form-row-3",
                        "type": "LhRow",
                        "actions": [],
                        "attributes": [],
                        "styles": [],
                        "children": [
                          {
                            "key": "pharmacy-detail-col-3-left",
                            "type": "LhCol",
                            "actions": [],
                            "attributes": {
                              "cols": "auto",
                              "md": 6
                            },
                            "styles": [],
                            "children": [
                              {
                                "key": "pharmacy-edit-form-row-1",
                                "type": "LhRow",
                                "actions": [],
                                "attributes": [],
                                "styles": [],
                                "children": [
                                  {
                                    "key": "pharmacy-detail-col-1-left",
                                    "type": "LhCol",
                                    "actions": [],
                                    "attributes": {
                                      "cols": "auto",
                                      "md": 6
                                    },
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "pharmacy-visitFrequency",
                                        "type": "LhInput",
                                        "actions": [],
                                        "attributes": {
                                          "type": "number",
                                          "label": "PHARMACY_VISITFREQUENCY",
                                          "name": "visitFrequency",
                                          "id": "pharmacy-visitFrequency-cru",
                                          "value": 0,
                                          "disabled": false
                                        },
                                        "styles": [],
                                        "children": []
                                      }
                                    ]
                                  },
                                  {
                                    "key": "pharmacy-detail-col-1-right",
                                    "type": "LhCol",
                                    "actions": [],
                                    "attributes": {
                                      "cols": "auto",
                                      "md": 6
                                    },
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "pharmacy-nextVisitDate",
                                        "type": "LhInput",
                                        "actions": [],
                                        "attributes": {
                                          "type": "date",
                                          "label": "PHARMACY_NEXTVISITDATE",
                                          "name": "nextVisitDate",
                                          "id": "pharmacy-nextVisitDate-cru",
                                          "value": "2022-08-08",
                                          "disabled": false,
                                          "labelIcon": {
                                            "tooltip": "PHARMACY_NEXT_VISIT_TOOLTIP"
                                          }
                                        },
                                        "styles": [],
                                        "children": []
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "key": "wholesalerSearch",
                                "type": "LhInput",
                                "actions": [],
                                "attributes": {
                                  "type": "select",
                                  "label": "PHARMACY_WHOLESALER",
                                  "name": "wholesalerSearch",
                                  "id": "pharmacy-preferredWholesalerId-cru",
                                  "content": [
                                    {
                                      "text": "GENERAL_CHOOSE",
                                      "value": ""
                                    },
                                    {
                                      "value": "76618cf3fef2e84caa381042d8d09b0460f9b52bc73485f2f195f9e67c3aa3b5379687d8dc300993505b7471cc33927702b79ce3c6cc58e1e21657afea36eca3",
                                      "text": "ADC"
                                    },
                                    {
                                      "value": "8582835db317ab9417e5d238aec142678e06386da97608677ba2665dcd457e2833a4e0991fc5044bb5debd4e97282e56e4b0f29b4e409ea3f5102bbf46fd243a",
                                      "text": "BELMEDIS  ANTWERPEN"
                                    },
                                    {
                                      "value": "a53f8a4315dffd51cc9e76ebdda0ac6ad543381ae120468db5167a8108c0b8ce5249c567fa2e7849f14b29dcd0d5e7c73d3f5ae5e1c29ede77d60a2b0a5429b9",
                                      "text": "BELMEDIS  DRONGEN"
                                    },
                                    {
                                      "value": "7ba39b01d55130bc071e5fda1ca4d753e583ae9161d0f3bb109cea48862bcc6f9a1351330abc13ddd200ab9fb7607d310dba541ec7a670524e569f16758b7624",
                                      "text": "BELMEDIS  LONGLIER"
                                    },
                                    {
                                      "value": "d6e26fb398e21060da7db655d4573030668aac74bd99fd43ca01966f2a6e7b8d6df710ae9ab7125682ce6daaf96a299ed95551bd874962c824247453ff8e5942",
                                      "text": "BELMEDIS  Sterpy--> Seneffe NEW"
                                    },
                                    {
                                      "value": "90dbad9171dba1efbe578ab4478046f1df6eae397f22cb7988131ed2cb406128ce9f922da59d9b3f0024901f8c04606eacf5d57617fac20dea6546b5152ca961",
                                      "text": "BELMEDIS ALLEUR "
                                    },
                                    {
                                      "value": "0f2e7b546b25cfcdc1b5cc1141a309fe9ba127c3a3d6cf3db51d1e35841c3953b51e4ada19e25ad19151b561a2786303f12685ddf6995114f0070442d5ee0f30",
                                      "text": "BELMEDIS TESSENDERLO"
                                    },
                                    {
                                      "value": "e2354d5f83d5b65a18cd5e915b4ad1cca0f18bbadef6606e3b223a2e1a500c0d0ce1d3443da9fcb1af3ac1072e4c6644e0e98c962403d27786b09a3c77a9ad1a",
                                      "text": "CERP ANDERLECHT"
                                    },
                                    {
                                      "value": "7e54ec686b65480f30dbc9501b04977ff8c88e9eaacdc26e56246c1d9293ea989392185639d3a72fc8e6b9923e1d54d502b7ceef1e92dbd63e48f76472e4fb38",
                                      "text": "CERP HAREN"
                                    },
                                    {
                                      "value": "01313f9f460ea8334b9391652c3cb1e33896c46f7dadb2e705cd0bbedbbc01e2a27723562e594ccaea2e1795891aca7673a90146760100e9c5e1b29a1af213f1",
                                      "text": "CERP TOURNAI"
                                    },
                                    {
                                      "value": "d1bc55a1807df071f1710f99233540cd6beeb706417b39f8159db365173608b77c625e7824823a90b1f54321396161f1b34ca40239152b82bc82b22ad6179d65",
                                      "text": "CERP WALLONIE"
                                    },
                                    {
                                      "value": "040c0d437b5710ef1e1646fc0710d70ec1346626b154f316713cbaf29c51cfeca6fe9c9dffaabdab7ed4201047db8e4325a2ac946004e3c3e5d525fa0e661fa0",
                                      "text": "DE VOORZORG HASSELT "
                                    },
                                    {
                                      "value": "c4e63f7f46bdae6a8cd3b9d058b8898ca259495e7155927068df81a07b323a5e58ca40ab4783ac45e6c048660a065634f77b75c944423a0b5ff18894b95930ec",
                                      "text": "DE VOORZORG MECHELEN"
                                    },
                                    {
                                      "value": "c39970e38d896cfb1141c96ad0d2d3189d2be0e34a0512942fcfaefb837cb0923cf50f9b3dd1df81557061f8c093a8254a405259ce64cc67023e6f16d60f33e7",
                                      "text": "EPECE S.C."
                                    },
                                    {
                                      "value": "0187a98df9cadfbc6fbe4a5a7c29af442fb3c35905918cee666b4f385bf84541488f7ef9de500d465eee34f7a072fc655fcd39f5a5430aa65fd1efdf9820d6c8",
                                      "text": "FEBELCO BRUGGE"
                                    },
                                    {
                                      "value": "f6f695e92e3b06b348c17f3216f5860a234476aa7309f3a57c033d76e7da2e54c949cf99aa1e7810b92c2a43eceffc43e7dc2a66cdd5e24729137e3593813842",
                                      "text": "FEBELCO IZEGEM"
                                    },
                                    {
                                      "value": "56f637317763377592729ff01562ee6d15fcc7dc749dcc0fa3b8bd6f71c1871c3e351327c8bc3244e7b5c0c65bd6189d340cf04a05e962bdc9a936f063a1f92d",
                                      "text": "FEBELCO KORTENBERG"
                                    },
                                    {
                                      "value": "f2f8a9e75f50aec43b49c85ec3f9ff3d061e4615e17913e40803f88a3a5573d0ab9b457a6c2c3a74cf711cab1b170e1e88ac97f078b11d72e03dfc7cc3cb362f",
                                      "text": "FEBELCO SINT NIKLAAS"
                                    },
                                    {
                                      "value": "6de49f3670d6a3cefdadaac57f2c6e5129f9c40c6c68f54c233557328aff7c2628304acd6ebd66485cff030efad73ccff99b8906212d5daf511b2740bda8d03b",
                                      "text": "FEBELCO WIJNEGEM"
                                    },
                                    {
                                      "value": "6129d480ffba0c3d2f88f64c1926276f17524847c0491b9f6d9c537ec51db42a70db021c8ebd959b16e2a81287809937d294f7b669fbdf0e74396447462f08bb",
                                      "text": "FEBELCO ZOLDER"
                                    },
                                    {
                                      "value": "c62fd5f474dbba01e0ad0068d5cdfcdc782491c4e6d5533377f2468d725a57579b6f5d7d3c368d2335a6875d076e99d0daa5412d5ecc547fded90ad96b6869f4",
                                      "text": "GOED"
                                    },
                                    {
                                      "value": "123be940f2ab67317ef4d53bafcb54437ff34771450260b355e7132492848c6f58a7aefafbe3275f9c52660e8d8eddee1c5a87d2a1c3464573b62d7446d0222d",
                                      "text": "HOSPILUX"
                                    },
                                    {
                                      "value": "38b5cbf726a848eb1f5cc000acbe8366422032618b7662adedc5f26616b530613844fe6855cbf6fa46c4e37b3c58679d9ab89c51b61e9867f13a43614bd7c10b",
                                      "text": "MAUROY"
                                    },
                                    {
                                      "value": "a128cc4c09a06d70b95894e39dc057b47eae1fba9c389c52f8724e4ed5d7bf47d96cea526b9c74516584f95cf9d4d7c5bc47d4993d825d10ff4bf96e6d9a8248",
                                      "text": "MULTIPHARMA"
                                    },
                                    {
                                      "value": "8be04b50fa41091f11baabf154123eec8297f3691f96c976b7b542e6a22cc2629730b351cca986ae617effb4386d5246e2d7299b502170af2dc168beacd7df1f",
                                      "text": "PHARM. POP. LIEGEOISES"
                                    },
                                    {
                                      "value": "82e6625d5d6f3c32d323ef60465f1f838582564c60b77c50407c67766f286ae421205127478492b2b677293465949b01e3d621be80879830c65587e8ef201474",
                                      "text": "PHARM. POP. VERVIERS"
                                    },
                                    {
                                      "value": "bc0cfa847ac3a4f2de9054138d0c5f0ae49e98b1c446db46530b1b9b093468fa04b60777d8c88653d1c511044f267bb14daa1859cf391ae61cbe5490a481c877",
                                      "text": "PHARMA BELGIUM EPPEGEM"
                                    },
                                    {
                                      "value": "acce836adcced144df3aeeef384c9b74e87f0a5ac9d59274c3fe5cfad3ec474b02e8f6a5dfcac897701a2e1b2251538d11d2c42534ffc7f178bfa98b165387d8",
                                      "text": "PHARMA BELGIUM N.V. DEURNE"
                                    },
                                    {
                                      "value": "b917c436a5d1e12fa0bee9f3b93cb4c07e88a3ebbfabdc57b98edb51ef3636f5e7fea8369395deac4a788ecbbb14c58a799f4208ce782c0cc8596365d20d4009",
                                      "text": "PHARMA BELGIUM S.A. BRUXELLES"
                                    },
                                    {
                                      "value": "847948d9303d8573af18e585687165e149a7125bfeffba7923c6a9652484f2aa60bf8ae7b78f8b0ee048f009f6b0ec4116ad95d80f64436d1ce0c8106d0b417e",
                                      "text": "PHARMA BELGIUM S.A. GRACE-HOLL"
                                    },
                                    {
                                      "value": "3dd14614e4f6288ca429e8ac0715c55d0f6325b6bf86f8a98d3c0864a1c619d21985935a04c6576b7d381d7ec2e11a2925e5808e5e5717848fedde52cc843350",
                                      "text": "PHARMA BELGIUM S.A. WALLONIE"
                                    },
                                    {
                                      "value": "4c34ff7d5321c9484a1f6bf6d273630a9c84793e4b99684d4277d702cca8c065a8a5d9cf7fb4f1c5878ba9ecd20c05235a556f1b8f18caeae9814a60291f9743",
                                      "text": "PHARMA BELGIUM ZWIJNAARDE"
                                    },
                                    {
                                      "value": "f83c68f885915813c0884a7266d8c8049330abadd19a3e7de3ecfeed514732b65b5edbe63407417cbe94a444819509ea776ce495588364be21a222f38bc5c897",
                                      "text": "PHARMA GOEDERT"
                                    },
                                    {
                                      "value": "fd6fe588bcef96d8e302b6972eb22a68cea94f65532f0672e3086e1c45fb5a628a5aa9cd577f3e73bfd7dda14764d4a1eb3831b9e93c4916a38c303943264f3a",
                                      "text": "PHARMA SANTE"
                                    },
                                    {
                                      "value": "012be12c80e6116893241b0377b743e4c2baec8273640c85b09fa5136143f7997248f145df3b3b5d1c1cfc7ad3e9b5cd83d1eb57436a41cbdb84b75d372398b1",
                                      "text": "Pharmabase"
                                    },
                                    {
                                      "value": "f8313fd20e313977ee781478c92189098ea6f553c144efea8860a5cecbe120fdff846c01c907691cdc35217ea4102e0c49b397b6b923b88b8b53a6cda6799cc7",
                                      "text": "VOORUIT GROOTHANDEL"
                                    }
                                  ],
                                  "disabled": false,
                                  "value": "f6f695e92e3b06b348c17f3216f5860a234476aa7309f3a57c033d76e7da2e54c949cf99aa1e7810b92c2a43eceffc43e7dc2a66cdd5e24729137e3593813842",
                                  "labelCols": 12
                                },
                                "styles": [],
                                "children": []
                              },
                              {
                                "key": "sectorSearch",
                                "type": "LhInput",
                                "actions": [],
                                "attributes": {
                                  "type": "select",
                                  "label": "PHARMACY_SECTOR",
                                  "name": "sectorSearch",
                                  "id": "pharmacy-SectorId-cru",
                                  "content": [
                                    {
                                      "text": "GENERAL_CHOOSE",
                                      "value": ""
                                    },
                                    {
                                      "value": "ab0f880953ce0c379deec09c8cbb418a31674b039a016388332558f4ab84c49686955fd26d933777aa638fff6290e7d4224a0446e928e2620e6be67e91258fc7",
                                      "text": "inside sales  2"
                                    },
                                    {
                                      "value": "138484ccd444d010211317217b4014e425bb5e1154515216a9e2bdfa0a1a70589961669c7fff2d744091b9ff3cc183ee8c0d32c950921524317540cc686a2fbf",
                                      "text": "inside sales 1"
                                    },
                                    {
                                      "value": "3fdf1a212387c52a1ee5743c2b84e8ccaceb6a176e632f554f450fcbc921f6ce9e51ad6bc9e56ca73e790a8b53634f9aa41300f4b5e595f22fec8315291c88fa",
                                      "text": "KAM"
                                    },
                                    {
                                      "value": "c51ab685288c95d795ac02407ab698eabfbe7ee79be76cca3ce27c3bfc7253bdb35dfdc14735691acd1ad68c534ecc33d67c1aa6983bb638a3fe54efbd82ec00",
                                      "text": "outside sales 1"
                                    },
                                    {
                                      "value": "e10c1864c4d75e64ab96f7488a6873b7b716d1b197bed9d83437814c1e6a6cb1cd61500d7d7aa2e46fea1c7c6c1c2cbaa671c2b9aa3be19195b0eb428c0a0be3",
                                      "text": "outside sales 2"
                                    },
                                    {
                                      "value": "c6413192ff0e01ea311c1c0e6c4be76f564bb19ab771c4dc078a35e5759c9cfeca9bc358298c62378cd2a90efcb8f4d3b6c68a08d81eadd581ac632386b74147",
                                      "text": "outside sales 3"
                                    },
                                    {
                                      "value": "518078787832a2bf9a6990a9a83424f7e302c8cf1b887fd0b43d90486a84df08528d8db73e21b5a24482e1a5f8c2228d60356f89c10ee12fc0f36bbdd84dda53",
                                      "text": "outside sales 4"
                                    },
                                    {
                                      "value": "5214a425e6609065d79b5243d6264976dd8fda2d95aa4c20a3c17feeed64fb322d68ad9bc42c8cb871446f7f72190161520ec87c95b979d9f13432c775bfeeba",
                                      "text": "Test sector"
                                    }
                                  ],
                                  "disabled": false,
                                  "validation": [
                                    {
                                      "type": "required",
                                      "translation_key": "GENERAL_VALIDATION_REQUIRED",
                                      "translation_key_custom": null
                                    }
                                  ],
                                  "value": "5214a425e6609065d79b5243d6264976dd8fda2d95aa4c20a3c17feeed64fb322d68ad9bc42c8cb871446f7f72190161520ec87c95b979d9f13432c775bfeeba",
                                  "labelCols": 12
                                },
                                "styles": [],
                                "children": []
                              },
                              {
                                "key": "pharmacy-group-input-row",
                                "type": "LhRow",
                                "actions": [],
                                "attributes": [],
                                "styles": [],
                                "children": [
                                  {
                                    "key": "pharmacy-group-input-col-dropdown",
                                    "type": "LhCol",
                                    "actions": [],
                                    "attributes": {
                                      "md": 8
                                    },
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "pharmacyGroupSearch",
                                        "type": "LhInput",
                                        "actions": [],
                                        "attributes": {
                                          "type": "select",
                                          "label": "PHARMACY_PHARMACYGROUP",
                                          "name": "pharmacyGroupSearch",
                                          "id": "pharmacy-pharmacyGroupId-cru",
                                          "content": [
                                            {
                                              "text": "GENERAL_CHOOSE",
                                              "value": ""
                                            },
                                            {
                                              "value": "f3a05e9f7e1c66b0dae786718a710a6825ef00a2d66a7fb77b851a3f841aa86d71448d0d839ca7a1479ab8e97f1005ffc992dc9ccb6393365e10e44778e415a4",
                                              "text": "_independant"
                                            },
                                            {
                                              "value": "e059b0e1612e2194b9aa0409619f11519c8ad378a83c9e5025166eb2d8a267d0f00eadd8af80000a25d34c77acba76baed675a730a453350f0b986fe9a223073",
                                              "text": "AFS apothekerscollectief"
                                            },
                                            {
                                              "value": "47496271251b85bd0824a1cfa1bb1055ba67b5937f325967e49cdbbaa33756503a6f697708a3e8e4cf03328e83880b2d8bb7914bea03c2f526db1abb32a853c4",
                                              "text": "Amicus"
                                            },
                                            {
                                              "value": "832a5443e7d3d334eda9ae04cd9987076d7a341d64137756a00bfd2902441cb5cc18a647e560e4c20b00be2238a1ed2d7db1bd25ab8f3101c8e8ac257a71f846",
                                              "text": "Antverpia apotheken"
                                            },
                                            {
                                              "value": "c566b65dc74a2cc514e85c1f19483bc9d6576f7efe96f0fe631f37ec90bcfca9e85d12e492d1baf8a1ded18bee2f70fe13fc0c01912610c77c96cd44069e8684",
                                              "text": "Barbarin"
                                            },
                                            {
                                              "value": "663868033adb43e74f46bdcadd2f65c86aa2030088a9ae2b6b66ba49ecfbc20fb317d745d6606bb6d063189a050f0469807b4225c42ca99291ca422cdce44653",
                                              "text": "Bastin"
                                            },
                                            {
                                              "value": "225ef552deca792c1e6be10da1debdd487769384df6daac57f20ef2e986446adc2772282d8321b9d93b8bd6fbbfa71d7c35f0947c199d17970c43430b11e9d69",
                                              "text": "Braxburg"
                                            },
                                            {
                                              "value": "b146b9f06ef0a8665696dd5f06a9caf893a026e06f8b5d6d465faff1c7371c682eaf2a4a8749acc21602edadd0abbf4281bc53be9e7e9e269a7503e8b256afaa",
                                              "text": "Cobra"
                                            },
                                            {
                                              "value": "cde5439d107aa3326329ddfa6ffc6909bb92472b27f95a6eee584353b11f2a8fa885aa0ff54f9f5279ee94fa85e3ca9cd74c9f0a513b50cfeb63c191076bc7df",
                                              "text": "Coop apotheken"
                                            },
                                            {
                                              "value": "ede7a224edd77428cfc9e6d1e7748131f92be1f78894ee0c27487345ec49a1ada3c79219bb10f6a65687094023bf8472c6d9e450a74a316393cf39fd6a297d56",
                                              "text": "Cortebeeck"
                                            },
                                            {
                                              "value": "e8589335205bfb9db33456ab70cfdd9efc01aae1e3843af25ed85ed9464787e4c1f291e68a28e26b8278f501bf8b60dbe9b73b3ba6c5044ce5f442fb12a9038e",
                                              "text": "De Loecker"
                                            },
                                            {
                                              "value": "d02685e27ad948bdf16d0abf0ce5521d263ecb477557109925b070ddb9acb8ad1a88fb44d5b678188a678d887684bcc07cbf02a2f8f147ff9410789f34d293f7",
                                              "text": "De Statie"
                                            },
                                            {
                                              "value": "668606eb22f04e0d2dda34a89fe418aec74500c8a8c10bff7e98ba94f4fa2eea12599063dabfb748bf8d1005a48aa95d66c6d8f06a6aecdd269adae4cdea5c47",
                                              "text": "De Voorzorg apotheken"
                                            },
                                            {
                                              "value": "346970b64134570d03760909853079cb8a3b25b9a190d8836fa0cf659de53f4ba7243180ca9d9c26aa4373d0ec6d8475f86938343ac1b63c6d62f79d21fc0659",
                                              "text": "Delpature"
                                            },
                                            {
                                              "value": "ecf7ffefe46d35e01b85662ec17e6c61b2cf0692e359dd6cf5f6a8d85a6d388c43c3d172335292cd7b73d1eb6254b6d9c01af5b367e4059d008cf5297c956068",
                                              "text": "Dessein"
                                            },
                                            {
                                              "value": "bac8139d0c43eb261c74e412a0487d947131dffd839d9a1f84658d4105588aef32264d307cecea91c9b93175f0fe2f99fd407b571323ea063b0bb2d3e11e62d4",
                                              "text": "Dynaphar"
                                            },
                                            {
                                              "value": "e3361ccc153fdf8caeed9a1d634eff4ac1c1d9bdb5fe2e66fedaa67069df72242d4be510eee007408a7b365d02886c39e24fdf58ec5982ae02073ba4aaf068c9",
                                              "text": "Elsen"
                                            },
                                            {
                                              "value": "2c47044cdab492e0f09880fd47a02714a74f87dd6a77aad724e49e9aa62e0e9d6f443f9f1ce79b5ef57a29be58f40bffd5922fd8b4fda3cb54f681dc94b5a79a",
                                              "text": "Epc"
                                            },
                                            {
                                              "value": "503849042e0e4096b2fee8e68c0b27486e16d56339bbeb83df01fd29eb7810b7291de5abfb56d68012c1169bfec8b1c592ea022183b4927349eb2d5c17e637c3",
                                              "text": "Equiform"
                                            },
                                            {
                                              "value": "f2ed06e329a5097cb378c6192d7b63c08d6772e279779b6ac5a06665717009e08a5ded35b3f693c1fb28ad882b4babf003654db89dfd88200c39bf04b2c24ad8",
                                              "text": "Farmaline"
                                            },
                                            {
                                              "value": "b2addc0bd784d4418cc48446ec0aa5b3b1881d7a5fd8eeb97fa2ccebc5fe03ed6ed30068cc1475f0c193da2f035e803b5125977e60c62349697e66da398fc344",
                                              "text": "Goed"
                                            },
                                            {
                                              "value": "e9666bb4d6d3177392f09e4b99fc08f9227f577ba6a40ed2d8cd23fc898553c8d9ad355b121117789be195f5bf53517f800c1c14d74399049923d01d197ada7a",
                                              "text": "Groep Zele"
                                            },
                                            {
                                              "value": "35db7150f261e3283f1b316271414622ae4d7ec2bfe0b0e9c7a6cffdd3b90675aaa67f69a804999d6c756edbe9f4622ce8fe855c1013d36aaf4e4963327938fa",
                                              "text": "Groupe B"
                                            },
                                            {
                                              "value": "7b1ee9b5b4ad455ac060742427dfd81f564d4cc41c0be3c196702dbd4f915eea83d35a023620272127292bd6329c935461c8221a7933e834eeaff74510bec7cf",
                                              "text": "Havaux"
                                            },
                                            {
                                              "value": "8b6c8c4ab9960a495d5db64aa71687b97a8ba8eafdc9d6d33b128fb18a26c3dc9ac1e4b48d32e6dfda10803ee2860f7d47392652d16f377386968ceb1e7e97d3",
                                              "text": "Iasis"
                                            },
                                            {
                                              "value": "745e64c63012a612c080283612d88354fded69333c5b5bde0962f12cecc495becf0ffa1883539424fe1f459ea63fcb35569493febefd1fd47bd262f3fa26d00b",
                                              "text": "Inlec"
                                            },
                                            {
                                              "value": "2bc02c62de69a092c3eeddd738636d44ecf5c2629bbe85a329312f01a28661a2d414edfd8b9d625f03711a196ad59d69077633b4fec0705c4f950ea90ab46e1c",
                                              "text": "Keyenberg"
                                            },
                                            {
                                              "value": "7677128bdd078473d6c53a2504b3dc67cae2ffdfa45064df2381affb7970e69a68954711a40309faebe4fd1951cc27149dba4f71d5e40896834441fcaf144fbc",
                                              "text": "Lejeune"
                                            },
                                            {
                                              "value": "04d0fd91c1f86a0e7462a8a7774b06a59fd007b2a0c2f30747caa76f648315ef980364710ccc0504be1cec5de976744b00f0c8dd97fa12d8def5e834aa189c2b",
                                              "text": "Leroy"
                                            },
                                            {
                                              "value": "4f11bed2a1021ad32aa46e664e63a9f36a90913f1826bb51b459380539d188d74c4431fc5f78cf482bf4c53df8bd3783f3e79e71a94a37cee8eb27f6100d3e67",
                                              "text": "Lloydspharma"
                                            },
                                            {
                                              "value": "0e83449856fffd30afdd71bdc43fc2d566dbb66426894a5fe89975ae0bb01896d86d0af07c459fe252a7eac8360b181ac92c480617d71d6a8ad289d66a431a04",
                                              "text": "Medi-Market"
                                            },
                                            {
                                              "value": "20650f3da9cd293ae434042d34a94e8ae43241ed9c95a74a44020caef9692abb7232d2f844ec6189d157ed0928408894e4237c5939d77dd487de281c9375a3d6",
                                              "text": "Mees Apothekengroep"
                                            },
                                            {
                                              "value": "d94fee1eb9b67648ee39b6bf93940355093c1c3327480487aa582282a70bcfdf396d826019e17fc02529031a39bbf74569d87ff8a3de53cb39a360f08c281231",
                                              "text": "Multipharma"
                                            },
                                            {
                                              "value": "27b90734bbcedafdc03bc654ca76d506b5ea31f2aca0dabcecef07393bf4623068951acd97a5717c4006db7abfce5bbefcd85aacd2dee91bda113422449d1739",
                                              "text": "Panorama"
                                            },
                                            {
                                              "value": "048aee8b7b16e6e180b9370cd51f98197c586dfa4ef6e27e653e584dd6ebce30ebb32ade61c850d602ebabae18768de8b6b221604145662998e3fa21c18d21e1",
                                              "text": "Patrona"
                                            },
                                            {
                                              "value": "26bbf6b3e439757a63af6809db574149ac8b96031e863190dce88a1ffd5b34dfc92f2d833c3a556defc97426c5fb71cc70c75c35221cd5cbb411c069c7e9f142",
                                              "text": "Pharm Du Peuple"
                                            },
                                            {
                                              "value": "b3f2876cdc9e83b26f18687b87d941ae91c8dc1027fa9671afbabf41aa2ac41d4af2fa9bd4ca80f746fd3a3604be75dbf6d3311d42a194fcf8fd116d6e6d85e1",
                                              "text": "Pharm Un"
                                            },
                                            {
                                              "value": "e8c575b3f19936189dc0fed22bb77eddbe55d194d093c0dd28cd02f055399737e3a766708fd0484a1c84bef43cdf1867b55c5635d0f64825f6dc63d88e001702",
                                              "text": "Pharma Sante"
                                            },
                                            {
                                              "value": "6a6be1bc2fd01384b08513ac581cf4c37eadd40176ca789f2b26995eedba7f18f23fb954e52bec1cdf2a9893832828e322108cd54b51e105d986440a1f765b12",
                                              "text": "Pharmacie Uni"
                                            },
                                            {
                                              "value": "0d6f0228976db6fc048e97a491006d6b3bcce2feee761b583bab55193c0d8818a51dff10e039dff976b0cbbd33fac16ad68b3afa43ae5e1054fa94827df0352c",
                                              "text": "Pharmaciens Unis"
                                            },
                                            {
                                              "value": "a769c2dfdebedabfe0365bc6313fe5675311f060146e568ca949d94c804a3ee53160bdabae00af6c2dad15cfc11f8f6132fe9c0b757b18a353fe69f934262cb4",
                                              "text": "Pharmaplus"
                                            },
                                            {
                                              "value": "3fa0f8412334a068422222825378e5380f441b0aec88e5c8a1c54dd1872f9f2c3b505f1de2e5edcbe70f5a5f92c6ada0b8b59dd031b8e9435fd1071b6e7ead7d",
                                              "text": "Pharveda"
                                            },
                                            {
                                              "value": "8d3f6e062b7bcd9f64f6c871959822fade96c775f2d35400d05500adc0ed8943e134f68d4f7e8685a94259342b147b6c74bf55faa590957cae193b36f9ff334c",
                                              "text": "Ransart"
                                            },
                                            {
                                              "value": "ec0ac46782794d7fabc7bebf3cd59adcaf96ad8af6be136457e902e116cf7396cd700be070edc0e2625bc432bb55d901018d7cd904e9d87bb8449508d21a14fb",
                                              "text": "Santalis"
                                            },
                                            {
                                              "value": "d2b138418430aaa16595e846b9a404be0063dd85a08d20bc5259187eec5ba5441e0e0704c0323614cecc398bb9cc68f131d0ab5a2e63730f756d960eea30fbdc",
                                              "text": "Santis"
                                            },
                                            {
                                              "value": "bc6acce53d9bd6b09bc5cd90568f470008eec1d5d8f141f285e34913bf7d5f2d527950be81f54864d1f7ce7f6614a1556a66ee429942cb24e7f82223a30221b8",
                                              "text": "Sauvegarde"
                                            },
                                            {
                                              "value": "743b6e424b789b3703f9c04c0b70d82a12f268fbceab26eb69ec8af19e42977c01691f59c67ffad3113a4a23e222bc81cba761f36302a7fb822dbd1ade64e300",
                                              "text": "Servais"
                                            },
                                            {
                                              "value": "396a19eb65762594ff378acd5ee601d975254cc461eac718050b12d4c62a9c1587771afcee3c6f251c1e6448ac0e3b22a18adf0e0c982dac5b82ba01e66072cf",
                                              "text": "Sodiap"
                                            },
                                            {
                                              "value": "439dd876ed79e2cd5034c9ced4fa3645fb4c2518075e6d8b81cf83cb3ae467b3ef7287b82357394637aad9c000413cc11dd664aaf88f81d2c96cee759f6a397f",
                                              "text": "Sofiadis"
                                            },
                                            {
                                              "value": "e606891ab22c25e70fff1b713c2cfd4023f68cf8f37b7e5e06e5ff2684e3d734278d0e8c6e14dbf738fc53b6ffb2eed7a0a72656ad21d682e4c6eb6a55c847e3",
                                              "text": "Test Pharmacy Group"
                                            },
                                            {
                                              "value": "bce2268d7fb3f93308bebee9b9e9cd0b724eda4900bb1d3b9a4054459d001d3c8222613a85d410b64471f8fed781e1c9f64f2bc73139c7442e646443e4089ffd",
                                              "text": "Unipharma"
                                            },
                                            {
                                              "value": "d6e7214fad9fe5e2298ac44aab16b3dc880611c9ac3aafcf556fa6dd85079912217d6bda784da1ec21f1f0b5ffeb04d84da34b3559a1afc00186047daeb46cee",
                                              "text": "Uph"
                                            },
                                            {
                                              "value": "9b83d21ffeb5afc7ab0b3aef58b9bd2ab08f2aeed394477c2bb4f5bae67c09729a20f48e18a3111b40583db038a672fd8b4e073cf280102583029c19213c588e",
                                              "text": "V.Pharma"
                                            },
                                            {
                                              "value": "e121d82a15078f4835609de00c924a9319cf6a7a7c31050345a6847ae5e52d81e5eca067d842e3021b4ddc686147e9b28a29d557a6271c26b63d1af8feedaa5d",
                                              "text": "Voorzorg M"
                                            },
                                            {
                                              "value": "7d82663f78629ddfd9821a5388da2015f3ef6865a3442f8c5c0dd898304c38792dca17229ae1707613ce1edda4b392c8c0166acb0d05029ee908efa16de57e19",
                                              "text": "Zennevallei"
                                            }
                                          ],
                                          "disabled": false,
                                          "validation": [
                                            {
                                              "type": "required",
                                              "translation_key": "GENERAL_VALIDATION_REQUIRED",
                                              "translation_key_custom": null
                                            }
                                          ],
                                          "value": "f3a05e9f7e1c66b0dae786718a710a6825ef00a2d66a7fb77b851a3f841aa86d71448d0d839ca7a1479ab8e97f1005ffc992dc9ccb6393365e10e44778e415a4",
                                          "labelCols": 12
                                        },
                                        "styles": [],
                                        "children": []
                                      }
                                    ]
                                  },
                                  {
                                    "key": "pharmacy-group-input-col-checkbox",
                                    "type": "LhCol",
                                    "actions": [],
                                    "attributes": {
                                      "md": 4
                                    },
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "pharmacy-group-input-leading",
                                        "type": "LhInput",
                                        "actions": [],
                                        "attributes": {
                                          "type": "checkbox",
                                          "name": "pharmacyGroupLead",
                                          "id": "pharmacy-group-input-leading-cru",
                                          "value": "0",
                                          "disabled": false,
                                          "labelPlaceholder": true,
                                          "class": "mt-md-4"
                                        },
                                        "styles": [],
                                        "children": [
                                          {
                                            "key": null,
                                            "type": null,
                                            "actions": [],
                                            "attributes": {
                                              "value": "1",
                                              "content": "PHARMACY_PHARMACYGROUP_LEAD"
                                            },
                                            "styles": [],
                                            "children": []
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  {
                                    "key": "pharmacy-group-input-col-info",
                                    "type": "LhCol",
                                    "actions": [],
                                    "attributes": {
                                      "md": 12
                                    },
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "pharmacy-group-input-col-info-group",
                                        "type": "LhDiv",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": {
                                          "marginTop": "-0.8rem",
                                          "marginBottom": "1rem"
                                        },
                                        "children": [
                                          {
                                            "key": "pharmacy-group-input-col-info-text",
                                            "type": "LhSpan",
                                            "actions": [],
                                            "attributes": {
                                              "content": "PHARMACY_PHARMACYGROUP_CURRENT_LEAD"
                                            },
                                            "styles": {
                                              "fontSize": "0.8rem",
                                              "color": "#949494"
                                            },
                                            "children": []
                                          },
                                          {
                                            "key": "pharmacy-group-input-col-info-pharmacy",
                                            "type": "LhSpan",
                                            "actions": [],
                                            "attributes": {
                                              "content": ""
                                            },
                                            "styles": {
                                              "fontSize": "0.8rem",
                                              "color": "#949494"
                                            },
                                            "children": []
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "key": "pharmacy-purchase-cluster-row",
                                "type": "LhRow",
                                "actions": [],
                                "attributes": [],
                                "styles": [],
                                "children": [
                                  {
                                    "key": "pharmacy-purchase-cluster-col-dropdown",
                                    "type": "LhCol",
                                    "actions": [],
                                    "attributes": {
                                      "md": 8
                                    },
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "purchaseClusterSearch",
                                        "type": "LhInput",
                                        "actions": [],
                                        "attributes": {
                                          "type": "select",
                                          "label": "PHARMACY_PURCHASECLUSTER",
                                          "name": "purchaseClusterSearch",
                                          "id": "pharmacy-purchaseCluster-cru",
                                          "content": [
                                            {
                                              "text": "GENERAL_CHOOSE",
                                              "value": ""
                                            },
                                            {
                                              "value": "d60eda72958908fd12678b80f91ff5f82ba500c6b50f1446d09145520e00b6b825d43302009dd7b80c38f5f32683db2708d014bb1847377f4dfe939edf513505",
                                              "text": "Apo-Care Kortrijk"
                                            },
                                            {
                                              "value": "3e4fb0402d3a1b34239b399f804471f8aa283c47c9ce58de2ea3e3bd14d4307b0e73ef6d43b701ed558c0b8a2b96fe7686721ef9a1baff8d67c15b6f4bd8bfe3",
                                              "text": "APO2370"
                                            },
                                            {
                                              "value": "def743083c279e9084aec91707b0775dd39f42d9f52878808a04ec4b9eb71b24763eee44bedb52624cf65efe26bd8d5d05a79234a8eb18ee2d134d413d9ab15c",
                                              "text": "Apotheekteam"
                                            },
                                            {
                                              "value": "0b62b313bbfb1808f44edfb2c713fc7ff43ddac6e6c008a6020e8eb3caf2cc8f79633d40cf55630d379e899423bbfd97f68f5eef134ee439c6f29e8a99430737",
                                              "text": "BELLIPHARMA"
                                            },
                                            {
                                              "value": "99efd9736e28816939e70b70294d7b1d26ae6d9bc8ba490db92681d33744b35c2ab99004bd953092a2ce6f43adf4d978e8a2da2d99c3dfc1dc4bfff098d2b71f",
                                              "text": "Beuckelare"
                                            },
                                            {
                                              "value": "734da7bbc7fd87f9590b424c14935815269eeca542d793b85145e523901dc699356e5329696eda56ef3d62d343702feac2423e1804380692dc61843d3e2ef355",
                                              "text": "Biopharm"
                                            },
                                            {
                                              "value": "956a0a4318f6be0341aacaf38679c9e8bd80ac819fe28f1921c61a918138fb515aa4ee3fd018eb3a6ea63677446fd61a9fc5321c515d8d3870c93c016c4c88f0",
                                              "text": "Bontemps"
                                            },
                                            {
                                              "value": "b584452438be7e521d5f30ff49e5a8d5e4d7cf2521bdd984f4bbf30f48a1e852471928a590d535a2e2565c49948b846b4c94d656cc1afebd773616ba58857517",
                                              "text": "Cannaert Sint-Andries"
                                            },
                                            {
                                              "value": "a76a1cfb1b9d1902d6ac38f7673df6677dcc574dc06c80a662a1e9312aff033d654b05147a79414a002979db3c0d526d9effb93d50ad6a5f0df7068d9dc946a7",
                                              "text": "Casneuf"
                                            },
                                            {
                                              "value": "511792533801d1801017b684c7116f503446b0676564b23109f0e8a170b8d5f979803782e84ab33cd6b28caadc388e6df48ece8cfc404b112b8a9d7540ed4310",
                                              "text": "Clabots"
                                            },
                                            {
                                              "value": "36ef7f25e96ac7cd97da703b3569fbe60098a70274b57601adb0525afafc08a8782752671035d8c178d835a9f92564ce34d1da609b7e60a28dc5181c06287c87",
                                              "text": "De French Ieper"
                                            },
                                            {
                                              "value": "6bad0ce2dc7597c5f96ce2ad6618d78eb28d1343ba6824c6582bce69e903534fef1cedb6c12f13339567d0a2f64f9e0aab9081d6fd1c2a0d07ee8efd898ca398",
                                              "text": "De Zorgplek"
                                            },
                                            {
                                              "value": "e017af6edeed42ba98a5b533622407ea5ed0d2328d889ee9275977aeefbdde10f488b89c89c84a2ce7465bbee3ff4ec9ef29ccc0ef6f8a6dc54cb5004a279509",
                                              "text": "Delahaut"
                                            },
                                            {
                                              "value": "3f652eb73d051ee4076c6978d8cb8a785704db6e5abf528b38dc9c2ab411b88ff3573e85bd86cf2e48bf5934044938322430a19e5ee2f55a9bee11afc4ba94d3",
                                              "text": "Delahaut"
                                            },
                                            {
                                              "value": "593b72934289fbe466b348d0b5ba7ef1508b1af213ef041eb49ffe4c5881ca95b7f8c4d5227834ecf0805d301d1d3313ad2c1531094b703e2ec270e5b2dfe26d",
                                              "text": "DENIS"
                                            },
                                            {
                                              "value": "bddadee6aa0608c3f6654c3b8dd8d45637fced11f8a89098a80869766ebc150dff850e7888d6a7b7055426e447dc4e4a950e0818b89582759fd798d8c8753b09",
                                              "text": "Dochy"
                                            },
                                            {
                                              "value": "645f5bc25b9139ce29e13071f1e2ee1ad7ed8dfe644886ba5d0b0b1645cfb03a63ca42d86b42546c5a5c4efabddb23bda1a315146d603bc107c07697e632ae8b",
                                              "text": "Du Faux"
                                            },
                                            {
                                              "value": "42730682b64484cf7de1814bf795db0df9e5a8c09b89ce56447de946b8beab23be1beff66113156e374a2ddb2f8f51e331ed6d0b6f5ad9457a583b27fdac3da4",
                                              "text": "FARMADA"
                                            },
                                            {
                                              "value": "bb08b232d9aac1acba10276103eba3ae1770913bcf4655cb4c9b829c1f19317f097dd31ef5e5c3cace33a46648c2bb50024d86bcff6be73f6e97a255caad44e1",
                                              "text": "Fourmy"
                                            },
                                            {
                                              "value": "92eeb5a8297f3b5503fb35a22b5f6f4ad53f94c3ea7191772ee8914e7f6b910fc598449e412a8d3384e1a09c5b848300d98542b1d3619ca40d2c53593436c106",
                                              "text": "Gortz"
                                            },
                                            {
                                              "value": "9ff6441736edf12798a751eedf87a503b297d09bac3f02c038d8a36f173b16b9df7546237759656c859fe8b655c6ff31d9be6244817964851161bf7ebf912687",
                                              "text": "Groep Beirnaert"
                                            },
                                            {
                                              "value": "c3c7d28eea4d7e420dc55780a1f6f151c50a1ab98f58c03800761a75f2ff1a0e5dd447e0c6465afd848a1042196ad75ca32f3c285c46656711774be97773a1f8",
                                              "text": "Group Doms"
                                            },
                                            {
                                              "value": "e1298a963d6963faf5e98adbcfe3708229151ed16d76ff5321a2405c142a65e1b60f435b93ba0f6e88ff6cf4d26057bdde7371a84b8f941412405f76c9593a18",
                                              "text": "Hautekerke"
                                            },
                                            {
                                              "value": "b19739bd8655aa29016ee6e203c950170a34fa43abd28bf27ff0615d70b65a72da9ed784afbef8d39f721ea2e030b30798962a0c4958ce930c003bde907f6040",
                                              "text": "Hendrick  Carmeliet"
                                            },
                                            {
                                              "value": "f022a0212e362959db7a5010d3c7329836f44fa0a20eeb6d538c37edb72f3e3e3424b9a7511d0c8ddb7e41e2cdc324b6a448ae64d15d95bbf7a5fee5bdf0d3d1",
                                              "text": "Hertecant"
                                            },
                                            {
                                              "value": "ff7d2c8bda06f29ca0682a26f8e681b3f0f6e3967d262f621e34b37f001d64b229ffb966d9c546e906a90f7c15566977fdf2b9e52033c4b98cccc0c249c4c0a9",
                                              "text": "Heungens"
                                            },
                                            {
                                              "value": "0e4c6b53d7c68b6a5a222603c55b0d8e7a513e1b82bb57d2b5f23392a5edaf9333790e708b2f5cf572d8d04c8e34a6e5131656e043b00e8b31f6fc062d10cbf0",
                                              "text": "Holsbeek Groep"
                                            },
                                            {
                                              "value": "44e0235fee3267a865c9244f45ecaadfb8e9edf6e573d9ddf01840bb4ecb58c23e1fd1f7e6b256b7adde547c465acc5d6eacb7a9e522e4dda7cdfa5495622bec",
                                              "text": "Hoste Ruddervoorde"
                                            },
                                            {
                                              "value": "6f69025e35a1f15b29dea04a07cc744bb182b2243963632d8751f120519a102faa94bfb70262d351d2633f36434ad6758583d6de1cf1d8638d9cc91b5c53f758",
                                              "text": "Kinget"
                                            },
                                            {
                                              "value": "8f3a25ce17f4e6a85531b89281fd5271b88c374d3fe578a2376e0d002423882190b865e2cb06b1735455a99daa2fd600f937ea842ff47b9a3e6ae21b4cf4d057",
                                              "text": "LEQUENNE"
                                            },
                                            {
                                              "value": "82cb532bcfbdb6d4b8303e07945a6638323cf7d7b3252589b7c78ee4bc0c76f53852daabd05cfdddc7b78e413e877d6e29d80448c17584e9998130871d69bc19",
                                              "text": "Ludaphar"
                                            },
                                            {
                                              "value": "8f67628f77eeba59d5e3f0095e936488fe78eaf95aeed1701c55e57e1c2b21be1acce9494aee4a76ea8d2f2ab0a407f21e83d73b991a88ed7fccf8af4bd95c4d",
                                              "text": "Medifarm"
                                            },
                                            {
                                              "value": "632d27bd22a984e32576ea1bac9c1c534b90cdeed1b129a8d82bbe9c5c771ae6cd6b4ba29662ab72d7fbe89f156f390bb60a2422b0330536e26f8a4ed8519d89",
                                              "text": "Olifant Oostende"
                                            },
                                            {
                                              "value": "57809984ca1ad823b1eb00cdad329e1371cda74d2007d7382e3ee02979452813957dab77e034ba6abe339731abc824e761d7d50066eb11384f95ac644305b94b",
                                              "text": "Pharaphie Groep"
                                            },
                                            {
                                              "value": "decc845d8ac07c7e923362903fec897b7e31a05c4e79bdf9a6835b9560269345214a42f92b6ff81b915733dedf4387b2ea6aa5913bf3bd02504c8445c1ee4e4b",
                                              "text": "Pharmacie De Vriese"
                                            },
                                            {
                                              "value": "09cdce9da445f264d0b658306914cc1d9a4adb091c115b2e0686fa9f9cc7f742cbc4b9df2cd5dfc93ea9bbc00c3d0f7c280ad0da54a6ab2113ca824e9008d466",
                                              "text": "Pharmacie Du Midi"
                                            },
                                            {
                                              "value": "7d0b1f70aa1ddad7b50c20bacad1ce53fc7ff726bfd2af1913f32e535420e30b2e6de2305827b54a3a4762bab8e9af437e11aff0369fa98ece1aad7dc7e664c4",
                                              "text": "PHARMACIES DU CENTRE"
                                            },
                                            {
                                              "value": "014e9bc8a61710f94807fb89841751baa1a250801dc6cc14313363c34d461512a944fecd32b9f413e007cd1c3d093c45f6d4688741d2e2f94363ae69d4f77343",
                                              "text": "Pharmaforce"
                                            },
                                            {
                                              "value": "e5f2fc651aec35f8b702b4d34365cf7fb7a75318a3037a39c65f5ab3e577d9358708e3587077c662475e8105cc41c225d42e60696b9b3298dba0d3e6092edabc",
                                              "text": "PHARMAYOU"
                                            },
                                            {
                                              "value": "6b06741cbabc214e3d54ad775be77b1281a283d58bd8d11da637456d8ae11802f81ab27e926f8841f32080795befbb7f654157a4e7dac6f2667275f895a107cf",
                                              "text": "PLANTES"
                                            },
                                            {
                                              "value": "d4f3ab84f3b221a9fcfe08f18b24ce751d7ba9d3f2b90202f7fd8cbad6201432efacbaf70e8b3a36257367c97fa39558d84bfc956f3d67543d85e5f8bf36ed7c",
                                              "text": "PRIKKEN"
                                            },
                                            {
                                              "value": "d6fcaf424fe210d3e3652fbd6c054d525a746029ac578d326861c36dda084f482cf5a2e1902d93397ce5ab2509e4fe429c4a4a3cf2f7eca58c3e4a51dcb19c07",
                                              "text": "Remopharma"
                                            },
                                            {
                                              "value": "ea62ee0830dd4a23c2935f4a49b718462e46ca37c5c9694ea15c54dcf3d3fdbe935b859fed437dc4d38784aada1af5e9770e6b95a7b52a78af281310d7170a28",
                                              "text": "Renard"
                                            },
                                            {
                                              "value": "481f04a1b641fcd61e7e0e42d6abd25cd3a853a5f28698dd12f1ce096e3f6d6bb6b8a24f8e9fd0a602cc6791547ea2b4660ca559dc9cea56c2f58dd75f26f155",
                                              "text": "Romarix - De Vulder"
                                            },
                                            {
                                              "value": "c70db2e7760aec4858f4c45c1b892ac6969c129486f6a197faeb6ee6da4d4250842cc099a5418ebe01854710c0a070265225cd1372e25775b2b55cfb0871b951",
                                              "text": "Service Apotheek"
                                            },
                                            {
                                              "value": "ed19fac70565d9309e90cc2d10aaffac6173275994ce1c13042370f1da2607d69909f142b2ac338d22ba47d474323732ac1cf2fdd5b867950e4be373c7667247",
                                              "text": "Smits"
                                            },
                                            {
                                              "value": "b6d91b5d3bc979cb75f9c86e03181a364511cbb5ac1961c0340f01dcd3bf560d5ffeb142d6fb6f3b1118d75e03496c177eee83587152c41cc6c6a21e8bf30101",
                                              "text": "Sollie"
                                            },
                                            {
                                              "value": "ff0b73b3049447c64506bf6038e0f66997f3c600e9cd28972547f8b332fa6781beb5cc69d256e736f1340ffbd9f6b8a87b9e515d769e94a23d9f7a224edc829e",
                                              "text": "SPS nv"
                                            },
                                            {
                                              "value": "1946bee8b53e1c4f9cce57d8e07c46dd519b03aad58857803dc93240b0d10e7761cda62460211af36ca587891d9e960a97c78e1e3646367facb6bd3198722893",
                                              "text": "Strybol Brugge"
                                            },
                                            {
                                              "value": "03f1076b468e208658957e99fa2bf15f838bef37394d6d675d893ac64dea80f19a4a4dfcfce11c50edbed979662d4eceef3e1c699962a7d66f4866b67b2f4278",
                                              "text": "SUPERPHAR"
                                            },
                                            {
                                              "value": "7ece1b5dafe91b91b5089a4cdb58104302ad88fd24931d932f896da4151de10d8494f86ff8d269b9f0d95e9b42953dba3b0593b5893fc80118da6bbf9bad22d3",
                                              "text": "Tanghe-Vanneste"
                                            },
                                            {
                                              "value": "99e8c59396d9c12400912c1ab9b47fa8f036ffa797a7ad3533fd5de649d5351784a353ba36b9a21bf53431b92462571f3ce0bafaa3cfb966d82a52073b6f3633",
                                              "text": "Ten Aard"
                                            },
                                            {
                                              "value": "cf71c52598550f91d59e7d4979cfd0c41ba1e03179fba07fe66831c12da0fe5105d2ffc3f16f3fef0128ccfcf8fef1eb436d75478f9df62f22f09347511411a9",
                                              "text": "Test Purchase Cluster"
                                            },
                                            {
                                              "value": "d1049febc217461fab59e5e1161f59de5f7c00a4a86713420186a5974a7f24fb0e9b3c3cc515ddc233c4ca226071a1cef68e014ae083b9ed3592a396d2534b05",
                                              "text": "Unifarm"
                                            },
                                            {
                                              "value": "16c7a85fba7c20a188d91b0473367137eefd74b38a7e649f7400b1d867d110e07c2baf4ffcb41c247d52b55cbb873a99bcdf332e59e3d1edfcbdf6d2953680a3",
                                              "text": "Van Der Cruyssen"
                                            },
                                            {
                                              "value": "538586b6111fe5ad125aae8325e41b4562f39bd5ad14f0123cc487e103a07d0adf6f9a1c98e18efc5e3f44ed7493099dfd5d77859b2df2e74d21a1c46ebc9539",
                                              "text": "Van Hoey"
                                            },
                                            {
                                              "value": "f272783ce7734484e727d95c21fde69bce6865fcc53cc889a8f1b824533dcc67fd3a34046a444ca983cd3767469ad0d4476913f3d96afae54c97a522de8d9210",
                                              "text": "Vereenooghe Groep"
                                            },
                                            {
                                              "value": "1cd73b2e3d9486e358195ffb739d949e48bf0f963a5d2592f2063d7fc01fb7b3efa8da32b06e9171802d2d45e0dd07edbce7615075f1c52dcfe24a1bad5cc672",
                                              "text": "Verheijen"
                                            },
                                            {
                                              "value": "33c418ff455082128ee6f793a6ad1338f9f5f34604dc0c976b4231bd137d6cf05d5780527d884949c6df6b12780cc1121dbcf288c38711f6588fe12031c84f12",
                                              "text": "Viata"
                                            },
                                            {
                                              "value": "abf0bf73084d64fc754e6adee6a7e3f300238cd2457d5aa5e09c6f5960d297b3e0b47b21ae24accd41a2fa3d471a67abf40353adfdc4db25a09a3c55620d31dd",
                                              "text": "Vingerhoets"
                                            }
                                          ],
                                          "disabled": false,
                                          "value": "",
                                          "labelCols": 12
                                        },
                                        "styles": [],
                                        "children": []
                                      }
                                    ]
                                  },
                                  {
                                    "key": "pharmacy-purchase-cluster-col-checkbox",
                                    "type": "LhCol",
                                    "actions": [],
                                    "attributes": {
                                      "md": 4
                                    },
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "pharmacy-purchase-cluster-leading",
                                        "type": "LhInput",
                                        "actions": [],
                                        "attributes": {
                                          "type": "checkbox",
                                          "name": "purchaseClusterLead",
                                          "id": "pharmacy-purchase-cluster-leading-cru",
                                          "value": "0",
                                          "disabled": false,
                                          "labelPlaceholder": true,
                                          "class": "mt-md-4"
                                        },
                                        "styles": [],
                                        "children": [
                                          {
                                            "key": null,
                                            "type": null,
                                            "actions": [],
                                            "attributes": {
                                              "value": "1",
                                              "content": "PHARMACY_PURCHASECLUSTER_LEAD"
                                            },
                                            "styles": [],
                                            "children": []
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  {
                                    "key": "pharmacy-purchase-cluster-col-info",
                                    "type": "LhCol",
                                    "actions": [],
                                    "attributes": {
                                      "md": 12
                                    },
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "pharmacy-purchase-cluster-col-info-group",
                                        "type": "LhDiv",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": {
                                          "marginTop": "-0.8rem",
                                          "marginBottom": "1rem"
                                        },
                                        "children": [
                                          {
                                            "key": "pharmacy-purchase-cluster-col-info-text",
                                            "type": "LhSpan",
                                            "actions": [],
                                            "attributes": {
                                              "content": "PHARMACY_PURCHASECLUSTER_CURRENT_LEAD"
                                            },
                                            "styles": {
                                              "fontSize": "0.8rem",
                                              "color": "#949494"
                                            },
                                            "children": []
                                          },
                                          {
                                            "key": "pharmacy-purchase-cluster-col-info-pharmacy",
                                            "type": "LhSpan",
                                            "actions": [],
                                            "attributes": {
                                              "content": ""
                                            },
                                            "styles": {
                                              "fontSize": "0.8rem",
                                              "color": "#949494"
                                            },
                                            "children": []
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "key": "pharmacy-edit-form-row-2",
                                "type": "LhRow",
                                "actions": [],
                                "attributes": [],
                                "styles": [],
                                "children": [
                                  {
                                    "key": "pharmacy-detail-col-2-left",
                                    "type": "LhCol",
                                    "actions": [],
                                    "attributes": {
                                      "cols": "auto",
                                      "md": 6
                                    },
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "classificationSearch",
                                        "type": "LhInput",
                                        "actions": [],
                                        "attributes": {
                                          "type": "select",
                                          "label": "PHARMACY_CLASSIFICATION",
                                          "name": "classificationSearch",
                                          "id": "pharmacy-classificationId-cru",
                                          "content": [
                                            {
                                              "text": "GENERAL_CHOOSE",
                                              "value": ""
                                            },
                                            {
                                              "value": "ebbcee1f608e38cc246eb660ae8253659f7942b363e9f5aff7789db6f24a5670d2451d7b4d7d1843f9efaa44bcdde79728bb8f1a8bd55878ef149138431ec0e6",
                                              "text": "account grade A"
                                            },
                                            {
                                              "value": "a88354575fb03693fd1d2396868d0bc5cef4e434abc011e11e1bd6f999029ed1fe4579598a92e5edb6a04e16f647c043ed9be3d4d9b4ce46a0ee75e0745495a7",
                                              "text": "account grade B"
                                            },
                                            {
                                              "value": "7efa32bc97823fb3ed1fc90f76df5028aa985ea7e2af81b543daa7d9b8e99a087fb323724bacd5b2003299d680ae5ee6f3d726c941275f993afe534be6657266",
                                              "text": "account grade C"
                                            },
                                            {
                                              "value": "9cbaf02da6ebd9a515affe12c393da7a5702a2ef59e6ce3900c52e09128267db4b87f097b2ff2e8c789c9651e56bbcc8ab439f3747653db3453b1499064b0e08",
                                              "text": "account grade D"
                                            },
                                            {
                                              "value": "db1d7ad8044fb88161896b768821a8edf561a8649a35bca3d67e29cf9d5edc1a407d18754421277467528bfc4960e0cb148445b49d335b51b536336b877f6f14",
                                              "text": "account grade E"
                                            },
                                            {
                                              "value": "25660b15ed3da6d8cc260efec09ade4b87c632a32427b8ae1fe64e99782f1dc74b07ceb7d8887c2d6b559bdb28f3b301f04f2fea172df42d23b2f031f253ed95",
                                              "text": "contractual visits without sales (Z)"
                                            }
                                          ],
                                          "disabled": false,
                                          "value": "db1d7ad8044fb88161896b768821a8edf561a8649a35bca3d67e29cf9d5edc1a407d18754421277467528bfc4960e0cb148445b49d335b51b536336b877f6f14",
                                          "labelCols": 12
                                        },
                                        "styles": [],
                                        "children": []
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "key": "pharmacy-detail-col-3-right",
                            "type": "LhCol",
                            "actions": [],
                            "attributes": {
                              "cols": "auto",
                              "md": 6
                            },
                            "styles": [],
                            "children": [
                              {
                                "key": "pharmacy-newsletter-row",
                                "type": "LhRow",
                                "actions": [],
                                "attributes": [],
                                "styles": [],
                                "children": [
                                  {
                                    "key": "pharmacy-newsletter-row-col",
                                    "type": "LhCol",
                                    "actions": [],
                                    "attributes": [],
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "pharmacy-newsletter",
                                        "type": "LhInput",
                                        "actions": [],
                                        "attributes": {
                                          "type": "checkbox",
                                          "name": "newsLetterOptIn",
                                          "id": "pharmacy-newsletter-cru",
                                          "value": "0",
                                          "disabled": false
                                        },
                                        "styles": {
                                          "paddingTop": "0.5rem",
                                          "paddingBottom": "1rem"
                                        },
                                        "children": [
                                          {
                                            "key": null,
                                            "type": null,
                                            "actions": [],
                                            "attributes": {
                                              "value": "1",
                                              "content": "PHARMACY_NEWSLETTER_OPT_IN"
                                            },
                                            "styles": [],
                                            "children": []
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "key": "pharmacy-hr-group-1",
                                "type": "LhDiv",
                                "actions": [],
                                "attributes": [],
                                "styles": {
                                  "marginBottom": "2rem"
                                },
                                "children": [
                                  {
                                    "key": "pharmacy-order-visit-line-break-1",
                                    "type": "LhHr",
                                    "actions": [],
                                    "attributes": [],
                                    "styles": [],
                                    "children": []
                                  }
                                ]
                              },
                              {
                                "key": "summary-input-add-row",
                                "type": "LhRow",
                                "actions": [],
                                "attributes": [],
                                "styles": [],
                                "children": [
                                  {
                                    "key": "summary-input-add-row-left",
                                    "type": "LhCol",
                                    "actions": [],
                                    "attributes": {
                                      "cols": "auto",
                                      "md": 6
                                    },
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "pharmacy-orderSummaryByMail",
                                        "type": "LhInput",
                                        "actions": [],
                                        "attributes": {
                                          "type": "checkbox",
                                          "name": "orderSummaryByMail",
                                          "id": "pharmacy-orderSummaryByMail-cru",
                                          "value": "1",
                                          "disabled": false
                                        },
                                        "styles": [],
                                        "children": [
                                          {
                                            "key": null,
                                            "type": null,
                                            "actions": [],
                                            "attributes": {
                                              "value": "1",
                                              "content": "PHARMACY_ORDERSUMMARYBYMAIL"
                                            },
                                            "styles": [],
                                            "children": []
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  {
                                    "key": "summary-input-add-row-right",
                                    "type": "LhCol",
                                    "actions": [],
                                    "attributes": {
                                      "cols": "auto",
                                      "md": 6,
                                      "class": "d-flex flex-wrap justify-content-end"
                                    },
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "pharmacy-summary-add-button",
                                        "type": "LhButton",
                                        "actions": [
                                          {
                                            "url": "\/pharmacies\/summary\/ea01b853642989e5044cbb1220804bdf1d102fb741f999ee546657b36b3fad6c5e0b0399c1b5862472f6c3e62447bea66e4afd44cdc49ef6f55388cdfa428927",
                                            "type": "display",
                                            "method": "GET",
                                            "event": "click"
                                          }
                                        ],
                                        "attributes": {
                                          "content": "GENERAL_ADD",
                                          "transparent": true,
                                          "icon": "plus-circle",
                                          "form": "pharmacy-edit-basic-form"
                                        },
                                        "styles": [],
                                        "children": []
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "key": "pharmacy-summary-table-group",
                                "type": "LhDiv",
                                "actions": [],
                                "attributes": [],
                                "styles": [],
                                "children": [
                                  {
                                    "key": "pharmacy-summary-mini-browse-0",
                                    "type": "LhGridLite",
                                    "actions": [],
                                    "attributes": {
                                      "url": null
                                    },
                                    "styles": {
                                      "paddingLeft": "0px"
                                    },
                                    "children": [
                                      {
                                        "key": "row-0",
                                        "type": "LhGridRowLite",
                                        "actions": [
                                          []
                                        ],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                          {
                                            "key": "pharmacy-summary-name-heading",
                                            "type": "LhGridHeader",
                                            "actions": [],
                                            "attributes": {
                                              "content": "PHARMACY_ORDERSUMMARYBYMAIL",
                                              "cellType": "data",
                                              "showMobile": true,
                                              "disableSort": false
                                            },
                                            "styles": {
                                              "fontWeight": "900"
                                            },
                                            "children": []
                                          },
                                          {
                                            "key": "pharmacy-summary-action-heading",
                                            "type": "LhGridHeader",
                                            "actions": [],
                                            "attributes": {
                                              "cellType": "button",
                                              "class": "cell-sticky"
                                            },
                                            "styles": {
                                              "fontWeight": "900"
                                            },
                                            "children": []
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "key": "pharmacy-hr-group-2",
                                "type": "LhDiv",
                                "actions": [],
                                "attributes": [],
                                "styles": {
                                  "marginBottom": "2rem"
                                },
                                "children": [
                                  {
                                    "key": "pharmacy-order-visit-line-break-2",
                                    "type": "LhHr",
                                    "actions": [],
                                    "attributes": [],
                                    "styles": [],
                                    "children": []
                                  }
                                ]
                              },
                              {
                                "key": "reminder-input-add-row",
                                "type": "LhRow",
                                "actions": [],
                                "attributes": [],
                                "styles": [],
                                "children": [
                                  {
                                    "key": "reminder-input-add-row-left",
                                    "type": "LhCol",
                                    "actions": [],
                                    "attributes": {
                                      "cols": "auto",
                                      "md": 6
                                    },
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "pharmacy-visitReminderByMail",
                                        "type": "LhInput",
                                        "actions": [],
                                        "attributes": {
                                          "type": "checkbox",
                                          "name": "visitReminderByMail",
                                          "id": "pharmacy-visitReminderByMail-cru",
                                          "value": "1",
                                          "disabled": false
                                        },
                                        "styles": [],
                                        "children": [
                                          {
                                            "key": null,
                                            "type": null,
                                            "actions": [],
                                            "attributes": {
                                              "value": "1",
                                              "content": "PHARMACY_VISITREMINDERBYMAIL"
                                            },
                                            "styles": [],
                                            "children": []
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  {
                                    "key": "reminder-input-add-row-right",
                                    "type": "LhCol",
                                    "actions": [],
                                    "attributes": {
                                      "cols": "auto",
                                      "md": 6,
                                      "class": "d-flex flex-wrap justify-content-end"
                                    },
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "pharmacy-reminder-add-button",
                                        "type": "LhButton",
                                        "actions": [
                                          {
                                            "url": "\/pharmacies\/reminder\/ea01b853642989e5044cbb1220804bdf1d102fb741f999ee546657b36b3fad6c5e0b0399c1b5862472f6c3e62447bea66e4afd44cdc49ef6f55388cdfa428927",
                                            "type": "display",
                                            "method": "GET",
                                            "event": "click"
                                          }
                                        ],
                                        "attributes": {
                                          "content": "GENERAL_ADD",
                                          "transparent": true,
                                          "icon": "plus-circle",
                                          "form": "pharmacy-edit-basic-form"
                                        },
                                        "styles": [],
                                        "children": []
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "key": "pharmacy-reminder-table-group",
                                "type": "LhDiv",
                                "actions": [],
                                "attributes": [],
                                "styles": [],
                                "children": [
                                  {
                                    "key": "pharmacy-reminder-mini-browse-0",
                                    "type": "LhGridLite",
                                    "actions": [],
                                    "attributes": {
                                      "url": null
                                    },
                                    "styles": {
                                      "paddingLeft": "0px"
                                    },
                                    "children": [
                                      {
                                        "key": "row-0",
                                        "type": "LhGridRowLite",
                                        "actions": [
                                          []
                                        ],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                          {
                                            "key": "pharmacy-reminder-name-heading",
                                            "type": "LhGridHeader",
                                            "actions": [],
                                            "attributes": {
                                              "content": "PHARMACY_VISITREMINDERBYMAIL",
                                              "cellType": "data",
                                              "showMobile": true,
                                              "disableSort": false
                                            },
                                            "styles": {
                                              "fontWeight": "900"
                                            },
                                            "children": []
                                          },
                                          {
                                            "key": "pharmacy-reminder-action-heading",
                                            "type": "LhGridHeader",
                                            "actions": [],
                                            "attributes": {
                                              "cellType": "button",
                                              "class": "cell-sticky"
                                            },
                                            "styles": {
                                              "fontWeight": "900"
                                            },
                                            "children": []
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "key": "pharmacy-dashboard-tab-title",
                        "type": "LhH3",
                        "actions": [],
                        "attributes": {
                          "content": "PHARMACY_DISCOUNT_INFO"
                        },
                        "styles": [],
                        "children": []
                      },
                      {
                        "key": "pharmacy-sector-guid",
                        "type": "LhInput",
                        "actions": [],
                        "attributes": {
                          "type": "hidden",
                          "name": "guid",
                          "id": "guid",
                          "value": "ea01b853642989e5044cbb1220804bdf1d102fb741f999ee546657b36b3fad6c5e0b0399c1b5862472f6c3e62447bea66e4afd44cdc49ef6f55388cdfa428927"
                        },
                        "styles": [],
                        "children": []
                      },
                      {
                        "key": "pharmacy-edit-form-row-5",
                        "type": "LhRow",
                        "actions": [],
                        "attributes": [],
                        "styles": [],
                        "children": [
                          {
                            "key": "pharmacy-detail-col-5-left",
                            "type": "LhCol",
                            "actions": [],
                            "attributes": {
                              "cols": "auto",
                              "md": 6
                            },
                            "styles": [],
                            "children": [
                              {
                                "key": "pharmacy-customDiscountActive",
                                "type": "LhInput",
                                "actions": [],
                                "attributes": {
                                  "type": "radio",
                                  "name": "customDiscountActive",
                                  "id": "pharmacy-customDiscountActive-cru",
                                  "value": "0",
                                  "disabled": false,
                                  "stacked": true
                                },
                                "styles": [],
                                "children": [
                                  {
                                    "key": null,
                                    "type": null,
                                    "actions": [
                                      {
                                        "state": [
                                          "input#pharmacy-preferredDiscount-cru",
                                          "input#pharmacy-maximumDiscount-cru"
                                        ],
                                        "display": [{
                                          "select#pharmacy-pharmacyGroupId-cru": true,
                                          "select#pharmacy-purchaseCluster-cru": false
                                        }],
                                        "required": [
                                          "input#pharmacy-visitFrequency-cru"
                                        ]
                                      }
                                    ],
                                    "attributes": {
                                      "value": "0",
                                      "content": "PHARMACY_CUSTOMDISCOUNTINACTIVE",
                                      "disabled": false
                                    },
                                    "styles": [],
                                    "children": []
                                  },
                                  {
                                    "key": null,
                                    "type": null,
                                    "actions": [
                                      {
                                        "state": [
                                          "input#pharmacy-preferredDiscount-cru",
                                          "input#pharmacy-maximumDiscount-cru"
                                        ]
                                      }
                                    ],
                                    "attributes": {
                                      "value": "1",
                                      "content": "PHARMACY_CUSTOMDISCOUNTACTIVE",
                                      "selected": true
                                    },
                                    "styles": [],
                                    "children": []
                                  }
                                ]
                              },
                              {
                                "key": "pharmacy-edit-form-row-4",
                                "type": "LhRow",
                                "actions": [],
                                "attributes": [],
                                "styles": [],
                                "children": [
                                  {
                                    "key": "pharmacy-detail-col-4-left",
                                    "type": "LhCol",
                                    "actions": [],
                                    "attributes": {
                                      "cols": "auto",
                                      "md": 6
                                    },
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "pharmacy-preferredDiscount-0",
                                        "type": "LhInput",
                                        "actions": [],
                                        "attributes": {
                                          "type": "number",
                                          "label": "PHARMACY_PREFERREDDISCOUNT",
                                          "name": "preferredDiscount",
                                          "id": "pharmacy-preferredDiscount-cru",
                                          "value": "",
                                          "disabled": true
                                        },
                                        "styles": [],
                                        "children": []
                                      }
                                    ]
                                  },
                                  {
                                    "key": "pharmacy-detail-col-4-right",
                                    "type": "LhCol",
                                    "actions": [],
                                    "attributes": {
                                      "cols": "auto",
                                      "md": 6
                                    },
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "pharmacy-maximumDiscount-0",
                                        "type": "LhInput",
                                        "actions": [],
                                        "attributes": {
                                          "type": "number",
                                          "label": "PHARMACY_MAXIMUMDISCOUNT",
                                          "name": "maximumDiscount",
                                          "id": "pharmacy-maximumDiscount-cru",
                                          "value": "",
                                          "disabled": true
                                        },
                                        "styles": [],
                                        "children": []
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "key": "pharmacy-detail-col-5-right",
                            "type": "LhCol",
                            "actions": [],
                            "attributes": {
                              "cols": "auto",
                              "md": 6
                            },
                            "styles": [],
                            "children": []
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
          ]
      },
    }
  }
}
</script>

<style scoped lang="scss">
</style>
